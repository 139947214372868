import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Moment from 'react-moment';
import 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { stringUtils } from '../_utils';
import { parametro } from '../_reducers/parametros.reducer';

export const ModalParametros = ({ modal, toggle, parametros, fonte }) => {

    const infoParametros = {
        textAlign: 'left',
        verticalAlign: 'middle',
        fontSize: 12,
        margin: 0,
        padding: 2,
    };

    //console.log("fonte: " + JSON.stringify(fonte));

    return (         
        <div>
            <Modal isOpen={modal} toggle={toggle} size="md" >
                <ModalHeader toggle={toggle}>Parâmetros da Análise</ModalHeader>
                <ModalBody>
                    {
                        (parametros != undefined ?
                            //parametros.map(a => {
                            parametros.sort((a, b) => a.parametroAgua.nome > b.parametroAgua.nome ? 1 : -1).map(a => {

                                var pH = parametros.find((element) => {
                                    return element.parametroAgua.nome === "PH";
                                })

                                //console.log("PARAM: " + a.parametroAgua.nome);
                                var cor_faixa = null;
                                var nome_faixa = null;
                                var faixa = null;
                                if (a.parametroAgua.nome != "NITROGÊNIO TOTAL") {
                                    faixa = stringUtils.getParametroFaixa(a.parametroAgua, a.valor);

                                    if (a.parametroAgua.id == 33) {
                                        console.log("FOSF>", faixa);
                                        console.log("VL FOSF>", a.valor);
                                    }
                                } else {
                                    //console.log("NITROGENIO FALSE");
                                    faixa = stringUtils.getParametroFaixaNitrogenio(a.parametroAgua, a.valor, pH.valor);
                                    //nome_faixa = stringUtils.getParametroFaixaNitrogenio(a.parametroAgua, a.valor, pH.valor).nome;
                                }

                                cor_faixa = faixa.cor;
                                nome_faixa = faixa.nome;

                                var valor_parametro = stringUtils.formataValor(a.valor);

                                //console.log("Parametro: " + a.parametroAgua.nome);
                                return (
                                    <p key={a.id} style={infoParametros}><b>{a.parametroAgua.nome + ": "}</b>
                                        <span>{valor_parametro + " " + (a.parametroAgua.unidadeMedida ? a.parametroAgua.unidadeMedida.abreviatura : "")}{(cor_faixa == undefined ? '' :
                                            <span><FontAwesomeIcon style={{ color: cor_faixa, marginLeft: 3 }} icon={faExclamationCircle} /> <span style={{ color: cor_faixa }}>{nome_faixa}</span></span>)}</span></p>
                                );
                            })
                        : '' )
                    }
                </ModalBody>
                <ModalFooter>
                    {(fonte == null ?
                        <p>Para saber mais sobre os parâmetros de qualidade da água <a href="https://fortalezatec.com.br/prosinos/saiba-mais" target="_blank">CLIQUE AQUI</a></p>
                        : '')}
                </ModalFooter>
            </Modal>
        </div>
    );

};