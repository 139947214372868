import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { pontosMonitoramentoAction } from '../_actions';
import { withRouter } from 'react-router-dom';
import { Button, Container, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { NavBarIndex } from '../_components/navbarIndex';
import { crudService } from '../_services';
import alerts from '../_components/alerts';
import Chart from 'react-apexcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faExclamationCircle, faStar, faLayerGroup, faFilter } from '@fortawesome/free-solid-svg-icons';
import { stringUtils } from '../_utils';
import { ModalParametros } from '../_components/modal';

const PontosMonitoramentosPublicos = (props) => {

    const dispatch = useDispatch();
    const [ponto, setPonto] = useState({});
    const [ultimaAnalise, setUltimaAnalise] = useState({});
    const [iqaCalculado, setIqaCalculado] = useState({});

    const [ana, setAna] = useState({});
    const [cemaden, setCemaden] = useState({});
    const [analise, setAnalise] = useState(null);

    const infoDados = {
        textAlign: 'left',
        verticalAlign: 'middle',
        fontSize: 13,
        padding: 3,
        margin: 0,
    };

    const infoObservacao = {
        textAlign: 'left',
        verticalAlign: 'middle',
        fontSize: 10,
        padding: 3,
        margin: 0,
    };

    const infoCurso = {
        textAlign: 'center',
        verticalAlign: 'middle',
        fontSize: 11,
        paddingTop: 2,
        margin: 0,
    };

    const infoParametros = {
        textAlign: 'left',
        verticalAlign: 'middle',
        fontSize: 12,
        margin: 0,
        padding: 2,
    };

    useEffect(() => {
        const { match: { params } } = props;

        if (params.id) {
            dispatch(pontosMonitoramentoAction.getPontosMonitoramentoPublicById(params.id));
        } else {
            dispatch(pontosMonitoramentoAction.clear());
        }

    }, []);

    useEffect(() => {
      //  console.log('analise', analise)
    }, [analise])

    useEffect(() => {

       // console.log('PROPS', props)

        const lista_ponto_monitoramento = props.pontoMonitoramento.pontoMonitoramento;


        if (lista_ponto_monitoramento.pontosMonitoramento != undefined) {

            const ponto_monit = lista_ponto_monitoramento.pontosMonitoramento[0];

            setPonto(ponto_monit.pontoMonitoramento);
            setUltimaAnalise(ponto_monit.ultimaAnalise);
            setIqaCalculado(ponto_monit.iqaCalculado);

            const id_ponto = ponto_monit.pontoMonitoramento.id;
            const nome_ponto = ponto_monit.pontoMonitoramento.fonteExterna != null ? ponto_monit.pontoMonitoramento.fonteExterna.nome : '';

            getInfo(id_ponto, nome_ponto);

        }

    }, [props])


    function getInfo(key, fonte) {

        if (fonte == '') {

            setAnalise(null);
            let filterCemaden = {
                IdPonto: key,
                Periodos: 8
            };

            alerts.waitingToast('Carregando histórico de IQA');
            let apiEndpoint = 'analises/grafico' + stringUtils.serialize(filterCemaden);
            crudService.get(apiEndpoint)
                .then((response) => {

                    if (response.data && response.data.graficoIQAPontoMonitoramento != null) {

                        response.data.graficoIQAPontoMonitoramento = {
                            options: {
                                chart: {
                                    id: "basic-bar",
                                    toolbar: {
                                        show: false
                                    },
                                },
                                xaxis: {
                                    categories: response.data.graficoIQAPontoMonitoramento.options,
                                    labels: {
                                        show: false,
                                        style: {
                                            fontSize: '9px'
                                        }
                                    }
                                },
                            },
                            series: [
                                {
                                    name: "IQA",
                                    data: response.data.graficoIQAPontoMonitoramento.series[0]
                                }
                            ]
                        };
                        setAnalise(response.data);
                        alerts.clean();

                    }
                    

                }).catch((err) => {
                    console.log(err);
                    alerts.error(err);
                });

        } else if (fonte == 'CEMADEN') {

            setCemaden(null);
            let filterCemaden = {
                IdPonto: key,
                Periodos: 96
            };

            alerts.waitingToast('Carregando histórico do ponto');
            let apiEndpoint = 'cemaden' + stringUtils.serialize(filterCemaden);
            crudService.get(apiEndpoint)
                .then((response) => {

                    if (response.data && response.data.graficoPluviometriaAcumulada != null) {

                        response.data.graficoPluviometriaAcumulada = {
                            options: {
                                chart: {
                                    id: "basic-bar",
                                    toolbar: {
                                        show: false
                                    },
                                },
                                xaxis: {
                                    categories: response.data.graficoPluviometriaAcumulada.options,
                                    labels: {
                                        show: false,
                                        style: {
                                            fontSize: '9px'
                                        }
                                    }
                                },
                            },
                            series: [
                                {
                                    name: "Chuva acumulada",
                                    data: response.data.graficoPluviometriaAcumulada.series[0]
                                }
                            ]
                        };
                        setCemaden(response.data);
                        alerts.clean();

                    }

                }).catch((err) => {
                    console.log(err);
                    alerts.error(err);
                });

        } else if (fonte == 'ANA') {

            setAna(null);
            let filterAna = {
                IdPonto: key,
                Datainicio: '',
                DataFim: ''
            };

            alerts.waitingToast('Carregando histórico do ponto');
            let apiEndpoint = 'ana' + stringUtils.serialize(filterAna);
            crudService.get(apiEndpoint)
                .then((response) => {

                    if (response.data && response.data.graficoChuva != null && response.data.graficoNivel != null && response.data.graficoVazao != null) {

                        response.data.graficoChuva = {
                            options: {
                                stroke: {
                                    curve: 'smooth',
                                },
                                chart: {
                                    id: "basic-bar",
                                    toolbar: {
                                        show: false
                                    },
                                },
                                xaxis: {
                                    categories: response.data.graficoChuva.options,
                                    labels: {
                                        show: false,
                                        style: {
                                            fontSize: '9px'
                                        }
                                    }
                                },
                            },
                            series: [
                                {
                                    name: "Chuva acumulada",
                                    data: response.data.graficoChuva.series[0]
                                }
                            ]
                        };

                        response.data.graficoNivel = {
                            options: {
                                stroke: {
                                    curve: 'smooth',
                                },
                                chart: {
                                    id: "basic-bar",
                                    toolbar: {
                                        show: false
                                    },
                                },
                                xaxis: {
                                    categories: response.data.graficoNivel.options,
                                    labels: {
                                        show: false,
                                        style: {
                                            fontSize: '9px'
                                        }
                                    }
                                },
                            },
                            series: [
                                {
                                    name: "Nível",
                                    data: response.data.graficoNivel.series[0]
                                }
                            ]
                        };

                        response.data.graficoVazao = {
                            options: {
                                stroke: {
                                    curve: 'smooth',
                                },
                                chart: {
                                    id: "basic-bar",
                                    toolbar: {
                                        show: false
                                    },
                                },
                                xaxis: {
                                    categories: response.data.graficoVazao.options,
                                    labels: {
                                        show: false,
                                        style: {
                                            fontSize: '9px'
                                        }
                                    }
                                },
                            },
                            series: [
                                {
                                    name: "Vazão",
                                    data: response.data.graficoVazao.series[0]
                                }
                            ]
                        };

                        setAna(response.data);
                        alerts.clean();

                    }

                }).catch((err) => {
                    console.log(err);
                    alerts.error(err);
                });
        }
    }

    let varCemaden;
    if (ponto.fonteExterna != null && ponto.fonteExterna.nome == 'CEMADEN') {
        varCemaden = cemaden;
    } else {
        varCemaden = null;
    }

    let varAna;
    if (ponto.fonteExterna != null && ponto.fonteExterna.nome == 'ANA') {
        varAna = ana;
    } else {
        varAna = null;
    }

    let varAnalise;
    if (ponto.fonteExterna == null) {
        varAnalise = analise;
    } else {
        varAnalise = null;
    }

    let item = {
        ana: varAna,
        cemaden: varCemaden,
        analise: varAnalise,
        //entidade: (ultimaAnalise ? ultimaAnalise.entidade : null),
        info: (ultimaAnalise ? ultimaAnalise.analisesParametros : null),
        iqa: (ultimaAnalise ? ultimaAnalise.iqa : null),
        //iqaCalculado: (iqaCalculado ? iqaCalculado : null),
    }

    return (
        <>
        <div>
            <NavBarIndex />
            <Container>
                <Card>
                    <CardHeader>

                            <>
                                <div>
                                    <Row>
                                        <Col md={12}>
                                        <span className="h6">{ponto.codigo && ponto.codigo + ' -'}  {ponto.nome}</span>
                                        {(ponto.curso != '' ? <p style={infoCurso}><b>CURSO D'ÁGUA: </b> {ponto.curso} {(ponto.codigoReferencia != '' ? '(' + ponto.codigoReferencia + ')' : '')}</p> : '')}
                                        </Col>
                                    </Row>
                                </div>
                                <hr style={{ margin: '5px' }} />
                                <div>
                                    <Row style={{ marginLeft: 0, marginRight: 0 }}>
                                        <Col style={{ textAlign: 'left', padding: '0' }} md={6}>
                                        {(ultimaAnalise.data != undefined ? <div style={infoDados}><b>DATA DA ANÁLISE: </b><br />{stringUtils.formataDataHora(ultimaAnalise.data)}</div> : '')}
                                        {(ultimaAnalise.entidade != undefined ? <div style={infoDados}>{(ponto.fonteExterna == undefined || ponto.fonteExterna.nome != 'ANA' || ponto.fonteExterna.nome != 'CEMADEN' ? <><b>LABORATÓRIO: </b><br /></> : '')}{ultimaAnalise.entidade.nome}
                                                {(ponto.fonteExterna != undefined && ponto.fonteExterna.nome == 'CEMADEN' ?
                                                    <a href={"http://sjc.salvar.cemaden.gov.br/resources/graficos/cemaden/hidro/hidrologica_interativo.html?est=" + ponto.codigoReferencia + "&uf=RS"} target="_blank"><FontAwesomeIcon style={{ marginLeft: 5 }} icon={faInfoCircle} /></a> : '')}</div> : '')}
                                        </Col>
                                        <Col style={{ textAlign: 'left', padding: '0' }} left="true" md={6}>
                                        {(item.iqa != undefined && item.iqa ?
                                            <p style={infoDados}><b>IQA: <span className="mr-2">{stringUtils.formataValor(item.iqa)}</span></b>
                                                <span className="badge btn ml-0 p-1" style={{ color: '#FFFFFF', backgroundColor: stringUtils.retornaCorIqa(item.iqa) }}>{stringUtils.retornaTextoIqa(item.iqa)}</span>
                                            </p>
                                                : '')}
                                            {(item.iqa != undefined && item.iqa != '0' ?
                                                <div>
                                                <p style={infoDados}><b>CURSO D'ÁGUA: </b><span className="mr-2">CLASSE {stringUtils.retornaClassificacao(item.info)}</span></p> 
                                                <p style={infoObservacao}><span className="mr-2">* Considerando os parâmetros acompanhados</span> </p>
                                                    </div>
                                                : '')}
                                        </Col>
                                        <hr style={{ margin: '3px', borderTop: '2px dotted #bebebe' }} />
                                    </Row>
                                </div>
                                    {(item.analise != null ?
                                    <div>
                                        <hr style={{ margin: '3px', borderTop: '2px dotted #bebebe' }} />
                                        <h6>Histórico do IQA</h6>
                                        <Chart
                                            options={item.analise.graficoIQAPontoMonitoramento.options}
                                            series={item.analise.graficoIQAPontoMonitoramento.series}
                                            type="line"
                                            height="180"
                                        />
                                    </div>
                                    : '')}
                                    {(item.cemaden != null ?
                                    <div>
                                        <hr style={{ margin: '3px', borderTop: '2px dotted #bebebe' }} />
                                        <h6>Acumulado de chuva</h6>
                                        <Chart
                                            options={item.cemaden.graficoPluviometriaAcumulada.options}
                                            series={item.cemaden.graficoPluviometriaAcumulada.series}
                                            type="line"
                                            height="180"
                                        />
                                    </div>
                                    : '')}
                                    {(item.ana != null ?
                                    <div>
                                        <hr style={{ margin: '3px', borderTop: '2px dotted #bebebe' }} />
                                        <small className="text-center">(Últimas 24 horas)</small>
                                        <Row style={{ marginLeft: 0, marginRight: 0 }}>
                                            <Col sm="4">
                                                <h6 className="text-center pb-0 mb-0">Chuva Acumulada</h6>
                                                <Chart
                                                options={item.ana.graficoChuva.options}
                                                series={item.ana.graficoChuva.series}
                                                    type="line"
                                                    height="150"
                                                />
                                            </Col>
                                            <Col sm="4">
                                                <h6 className="text-center pb-0 mb-0">Nível do Curso</h6>
                                                <Chart
                                                options={item.ana.graficoNivel.options}
                                                series={item.ana.graficoNivel.series}
                                                    type="line"
                                                    height="150"
                                                />
                                            </Col>
                                            <Col sm="4">
                                                <h6 className="text-center pb-0 mb-0">Vazão do Curso</h6>
                                                <Chart
                                                options={item.ana.graficoVazao.options}
                                                series={item.ana.graficoVazao.series}
                                                    type="line"
                                                    height="150"
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                : '')}
                            <div>
                                <Row>
                                    <Col md={12}>
                                        <span className="h6">Parâmetros da Análise</span>
                                    </Col>
                                </Row>
                            </div>
                            <hr style={{ margin: '5px' }} />
                            <div>
                                <Row style={{ marginLeft: 0, marginRight: 0 }}>
                                    <Col style={{ textAlign: 'left', padding: '0' }} md={6}>
                                        {
                                            (item.info != undefined ?
                                                //parametros.map(a => {
                                                item.info.sort((a, b) => a.nome > b.nome ? 1 : -1).map(a => {

                                                    var pH = item.info.find((element) => {
                                                        return element.parametroAgua.nome === "PH";
                                                    })

                                                    //console.log("PARAM: " + a.parametroAgua.nome);
                                                    var cor_faixa = null;
                                                    var nome_faixa = null;
                                                    var faixa = null;
                                                    if (a.parametroAgua.nome != "NITROGÊNIO TOTAL") {
                                                        faixa = stringUtils.getParametroFaixa(a.parametroAgua, a.valor);

                                                        if (a.parametroAgua.id == 33) {
                                                            console.log("FOSF>", faixa);
                                                            console.log("VL FOSF>", a.valor);
                                                        }
                                                    } else {
                                                        //console.log("NITROGENIO FALSE");
                                                        faixa = stringUtils.getParametroFaixaNitrogenio(a.parametroAgua, a.valor, pH.valor);
                                                        //nome_faixa = stringUtils.getParametroFaixaNitrogenio(a.parametroAgua, a.valor, pH.valor).nome;
                                                    }

                                                    cor_faixa = faixa.cor;
                                                    nome_faixa = faixa.nome;

                                                    var valor_parametro = stringUtils.formataValor(a.valor);

                                                    //console.log("Parametro: " + a.parametroAgua.nome);
                                                    return (
                                                        <p key={a.id} style={infoParametros}><b>{a.parametroAgua.nome + ": "}</b>
                                                            <span>{valor_parametro + " " + (a.parametroAgua.unidadeMedida ? a.parametroAgua.unidadeMedida.abreviatura : "")}{(cor_faixa == undefined ? '' :
                                                                <span><FontAwesomeIcon style={{ color: cor_faixa, marginLeft: 3 }} icon={faExclamationCircle} /> <span style={{ color: cor_faixa }}>{nome_faixa}</span></span>)}</span></p>
                                                    );
                                                })
                                                : '')
                                        }
                                    </Col>
                                    
                                    <hr style={{ margin: '3px', borderTop: '2px dotted #bebebe' }} />
                                </Row>
                                </div>

                            </>

                    </CardHeader>
                    <CardBody>
                        <Row style={{ marginLeft: 0, marginRight: 0 }}>
                            <p>Para saber mais sobre os parâmetros de qualidade da água <a href="https://fortalezatec.com.br/prosinos/saiba-mais" target="_blank">CLIQUE AQUI</a></p>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
            </div>
                <footer>
                <div className="container text-center">
                    <p style={{fontWeight: 100, color: "#808080", marginTop: 5, margin: 0}} >Site desenvolvido por</p>
                    <img src={require('../imgs/brand_fortalezatec_opacidade_60.png')} className="brand_fortalezatec" alt="logoFortalezaTec" />
                    </div>
                </footer>
        </>
    );

};

const mapStateToProps = (state) => {
    return {
        pontoMonitoramento: state.pontoMonitoramento
    };
};

const connectedPontosMonitoramentosPublicosPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(PontosMonitoramentosPublicos));
export { connectedPontosMonitoramentosPublicosPage as PontosMonitoramentosPublicos };